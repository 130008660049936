import React from "react";
import { graphql, useStaticQuery } from "gatsby";

//styles
// import * as styles from "../styles/pages/news-letters.module.scss";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import News from "../components/news";
import Layout from "../components/layout";
import Title from "../components/title";
import SEO from "../components/seo";

const NewsLetters = () => {
  return (
    <Layout>
      <Title picture title="Newsletters" />
      <SEO title="Newsletters" />
      <Container>
        <News />
      </Container>
    </Layout>
  );
};

export default NewsLetters;
